* {
  box-sizing: border-box;
}

/* Loading spinner, https://loading.io/css */

.centered-container {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.lds-dual-ring {
  display: inline-block;
  width: 80px;
  height: 80px;
}

.lds-dual-ring:after {
  content: " ";
  display: block;
  width: 64px;
  height: 64px;
  margin: 8px;
  border-radius: 50%;
  border: 6px solid #081275;
  border-color: #081275 transparent #081275 transparent;
  animation: lds-dual-ring 1.2s linear infinite;
}

@keyframes lds-dual-ring {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

/* Scrollbar */

*::-webkit-scrollbar {
  width: 8px;
}

*::-webkit-scrollbar-track {
  background: transparent;
}

*::-webkit-scrollbar-thumb {
  border-radius: 16px;
}

*::-webkit-scrollbar-thumb,
*::-webkit-scrollbar-thumb:hover,
*::-webkit-scrollbar-thumb:active {
  background: #979292;
}

/* Booking status */

.booking-status {
  cursor: pointer;
}

.booking-status > circle,
.booking-status > rect {
  stroke-width: 2;
}

.booking-status > text {
  fill: #FAFAFA;
}

.booking-status--free {
  /* fill: #237B4B; */
  fill: #5AE0B9;
  stroke: #E7F2DA;
  border: 2px solid #237B4B;
  background-color: #237B4B29;
}

.booking-status--free + text {
  fill: #212121;
}

.booking-status--blocked {
  fill: #FF4387;
  stroke: #FCF4F6;
  border: 2px solid #FF4387;
  background-color: #ff438729;
}

.booking-status--booked {
  fill: #20AEF3;
  stroke: #CED0E3;
  border: 2px solid #20AEF3;
  background-color: #20AEF329;
}

.booking-status--self-booked {
  fill: #081275;
  stroke: #CED0E3;
  border: 2px solid #081275;
  background-color: #08127529;
}

/* Carousel */

.slider-wrapper,
.slider {
  height: 100%;
}

.carousel {
  border-radius: 4px;
  overflow: visible !important;
}

.carousel-root {
  position: relative;
  max-height: 264px;
  min-height: 208px;
}

.carousel .slide {
  overflow: hidden;
}

.carousel .control-dots {
  bottom: -25px !important;
}

.carousel .control-dots .dot {
  background: #C4C4C4;
}

.carousel .control-dots .dot.selected {
  background: #5B5FC7;
}

/* Removing border on all select components */

.MuiSelect-select,
.MuiSelect-multiple {
  box-shadow: none;
}

.MuiOutlinedInput-notchedOutline {
  border: none;
  border-color: transparent !important;
}

.light .MuiInputBase-colorPrimary .MuiSelect-select {
  background-color: white !important;
}

.dark .MuiInputBase-colorPrimary .MuiSelect-select {
  background-color: #2e2e2e !important;
  color: white;
}
